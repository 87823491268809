import React from 'react';
import Link from 'next/link';
import { BehanceIcon, FacebookIcon, InstagramIcon, LinkedIcon, TwitterIcon, YoutubeIcon } from './SocialIcons';
import Image from 'next/image';

const Footer = ({ classes }) => {
  const currentYear = new Date().getFullYear();

  return (
    <footer
      className={`${classes ? classes : 'eduvibe-footer-one'} edu-footer footer-style-default kw-footer`}
      id='footer'
    >
      <div className='footer-top' style={{ paddingBottom: '60px' }}>
        <div className='container eduvibe-animated-shape mb-0'>
          <div className='row g-5'>
            <div className='col-lg-3 col-md-12 col-sm-12 col-12'>
              <div className='edu-footer-widget'>
                <div className='logo'>
                  <Link href={'/'}>
                    <a className='footer-link'>
                      <Image
                        src='/kaarwan/kaarwan-logo.svg'
                        alt='Footer Logo'
                        className='logo-light'
                        width={158}
                        height={24}
                        objectFit='contain'
                      />
                    </a>
                  </Link>
                </div>
                <p className='description footer-link'>
                  Kaarwan offers result-oriented workshops for architects & designers worldwide. We focus on
                  affordability and practical skill based learning to help our participants achieve their career goals.
                </p>
                <div className='row w-100 social-icons'>
                  <div className='col-lg-4 col-md-3 col-2 mb-5'>
                    <a
                      href='https://www.behance.net/kaarwan?tracking_source=search_users%7Ckaarwan'
                      target='_blank'
                      rel='noopener noreferrer'
                      aria-label='Kaarwan Behance Link'
                    >
                      <BehanceIcon />
                    </a>
                  </div>
                  <div className='col-lg-4 col-md-3 col-2 mb-5'>
                    <a
                      href='https://www.linkedin.com/company/kaarwan/'
                      target='_blank'
                      rel='noopener noreferrer'
                      aria-label='Kaarwan LinkedIn Link'
                    >
                      <LinkedIcon />
                    </a>
                  </div>
                  <div className='col-lg-4 col-md-3 col-2 mb-5'>
                    <a
                      href='https://www.instagram.com/kaarwan.india/'
                      target='_blank'
                      rel='noopener noreferrer'
                      aria-label='Kaarwan Instagram Link'
                    >
                      <InstagramIcon />
                    </a>
                  </div>
                  <div className='col-lg-4 col-md-3 col-2 mb-5'>
                    <a
                      href='https://www.facebook.com/Kaarwan.India/'
                      target='_blank'
                      rel='noopener noreferrer'
                      aria-label='Kaarwan Facebook Link'
                    >
                      <FacebookIcon />
                    </a>
                  </div>
                  <div className='col-lg-4 col-md-3 col-2 mb-5'>
                    <a
                      href='https://twitter.com/kaarwan_india'
                      target='_blank'
                      rel='noopener noreferrer'
                      aria-label='Kaarwan Twitter Link'
                    >
                      <TwitterIcon />
                    </a>
                  </div>
                  <div className='col-lg-4 col-md-3 col-2 mb-5'>
                    <a
                      href='https://www.youtube.com/c/KaarwanOrg'
                      target='_blank'
                      rel='noopener noreferrer'
                      aria-label='Kaarwan Youtube Link'
                    >
                      <YoutubeIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-6 col-md-12 col-sm-12 col-12 explore-links'>
              <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                  <div className='edu-footer-widget explore-widget'>
                    <h6 className='widget-title'>Find your fit</h6>
                    <div className='inner'>
                      <ul className='footer-link link-hover'>
                        <li>
                          <Link href='/#long-term-courses'>
                            <a className='footer-link text-nowrap'>
                              <i className='icon-Double-arrow'></i>
                              Career-Defining Courses
                            </a>
                          </Link>
                        </li>
                        <li>
                          <Link href='/#featured-courses' scroll={false}>
                            <a className='footer-link'>
                              <i className='icon-Double-arrow'></i>Most Loved Courses
                            </a>
                          </Link>
                        </li>
                        <li>
                          <Link href='/courses-and-workshops#explore'>
                            <a className='footer-link'>
                              <i className='icon-Double-arrow'></i>All Courses
                            </a>
                          </Link>
                        </li>
                        <li>
                          <Link href='/course-outcomes'>
                            <a className='footer-link'>
                              <i className='icon-Double-arrow'></i>Case Studies
                            </a>
                          </Link>
                        </li>
                        <li>
                          <Link href='/blog/homepage'>
                            <a className='footer-link'>
                              <i className='icon-Double-arrow'></i>Our Blogs
                            </a>
                          </Link>
                        </li>
                        <li>
                          <Link href='/travels/home'>
                            <a className='footer-link text-nowrap'>
                              <i className='icon-Double-arrow'></i>
                              Our Roadtrips
                            </a>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* Useful Link */}
                <div className='col-lg-6 col-md-6 col-sm-12 col-12 useful-links'>
                  <div className='edu-footer-widget quick-link-widget'>
                    <h6 className='widget-title'>Useful Links</h6>
                    <div className='inner'>
                      <ul className='footer-link link-hover'>
                        <li>
                          <Link href='/about'>
                            <a className='footer-link'>
                              <i className='icon-Double-arrow'></i>About
                            </a>
                          </Link>
                        </li>
                        <li>
                          <Link href='/contact-us'>
                            <a className='footer-link'>
                              <i className='icon-Double-arrow'></i>Get in Touch
                            </a>
                          </Link>
                        </li>
                        <li>
                          <a className='footer-link' href='https://forms.gle/V1oM5rev6soEmuxg9' target='_blank'>
                            <i className='icon-Double-arrow'></i>
                            Careers
                          </a>
                        </li>
                        <li>
                          <Link href='/become-instructor'>
                            <a className='footer-link'>
                              <i className='icon-Double-arrow'></i>Become an Instructor
                            </a>
                          </Link>
                        </li>
                        <li>
                          <Link href='/privacy-policy'>
                            <a className='footer-link' target='_blank'>
                              <i className='icon-Double-arrow'></i>Privacy Policy
                            </a>
                          </Link>
                        </li>
                        <li>
                          <Link href='https://kaarwan.s3.amazonaws.com/public/common/BHSx9_Form_MGT_7A_Kaarwan_FY%202022-23_Signed.pdf'>
                            <a className='footer-link' target='_blank'>
                              <i className='icon-Double-arrow'></i>
                              Annual Returns
                            </a>
                          </Link>
                        </li>
                        <li>
                          <Link href='/terms-and-conditions'>
                            <a className='footer-link' target='_blank'>
                              <i className='icon-Double-arrow'></i>
                              Terms & Conditions
                            </a>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-md-12 col-sm-12 col-12'>
              <div className='row'>
                <div className='col-12'>
                  <div className='edu-footer-widget footer-contact-section'>
                    <h6 className='widget-title'>Contact Info</h6>
                    <div className='inner'>
                      <div className='widget-information'>
                        <ul className='information-list'>
                          <li>
                            <i className='icon-phone-fill'></i>
                            <a href='tel: +91 9027454416' className='footer-link'>
                              +91 9027454416
                            </a>
                          </li>
                          <li>
                            <i className='icon-phone-fill'></i>
                            <a href='tel: +91 9833777469' className='footer-link'>
                              +91 9833777469
                            </a>
                          </li>
                          <li>
                            <i className='icon-mail-line-2'></i>
                            <a target='_blank' href='mailto:hola@kaarwan.com' rel='noreferrer' className='footer-link'>
                              hola@kaarwan.com
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='copyright-area copyright-default mt-5'>
        <div className='container mt-0'>
          <div className='row mt-0'>
            <div className='col-lg-12 mt-0'>
              <div className='inner text-center'>
                <p className='footer-link px-2 d-flex align-items-center justify-content-center my-1'>
                  Copyright &copy; {currentYear} Kaarwan Eduventures Private Limited. All Rights Reserved
                </p>
                <p className='mt-3 footer-link d-flex align-items-center justify-content-center'>
                  Crafted with <i className='ri-heart-fill text-danger mx-2'></i>
                  at IIT Roorkee India
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
